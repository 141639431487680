import { isDefined } from "@clipboard-health/util-ts";
import { CbhFeatureFlag, FEATURE_FLAG_DEFAULT_VALUES, useCbhFlag } from "@src/appV2/FeatureFlags";
import { differenceInMinutes, hoursToMinutes, isAfter, parseISO } from "date-fns";

import { type Shift } from "../Shift/types";
import {
  CancelShiftKey,
  EXCEEDED_SENT_HOME_HOURS_MESSAGE,
  NO_SENT_HOME_PAY_OFFER_BY_FACILITY_MESSAGE,
  NOT_EXCEEDED_SENT_HOME_HOURS_MESSAGE,
} from "./constants";

export interface CancelShiftFeedback {
  cancelShiftKey: CancelShiftKey;
  message?: string;
}

interface ShiftCancelFeedbackProps {
  isSentHomeFlowV2Enabled: boolean;
  shift: Shift;
}
function getShiftCancelFeedback(params: ShiftCancelFeedbackProps): CancelShiftFeedback {
  const { isSentHomeFlowV2Enabled, shift } = params;
  const isShiftStarted = isAfter(new Date(), parseISO(shift.start));
  const workerClockedOut = isDefined(shift.clockInOut?.end);

  if (!isSentHomeFlowV2Enabled) {
    return {
      cancelShiftKey: isShiftStarted ? CancelShiftKey.CANCEL_OR_SENT_HOME : CancelShiftKey.CANCEL,
    };
  }

  if (!isShiftStarted || workerClockedOut) {
    return {
      cancelShiftKey: CancelShiftKey.CANCEL,
    };
  }

  const { sentHomeChargeHours } = shift.facility;
  const clockIn = shift.clockInOut?.start;

  // Shift started but not Clocked in
  if (!isDefined(clockIn)) {
    return {
      cancelShiftKey: CancelShiftKey.CANCEL_OR_SENT_HOME,
    };
  }

  const timeWorkedInMinutes = differenceInMinutes(new Date(), parseISO(clockIn));
  const facilityHasSentHomePay = isDefined(sentHomeChargeHours) && sentHomeChargeHours > 0;
  if (facilityHasSentHomePay) {
    // Shift is in progress and is InstantPay eligible but worked beyond Sent home hours threshold.
    const hasExceededSentHomeHours = timeWorkedInMinutes >= hoursToMinutes(sentHomeChargeHours);
    if (hasExceededSentHomeHours) {
      return {
        cancelShiftKey: CancelShiftKey.CANCEL,
        message: EXCEEDED_SENT_HOME_HOURS_MESSAGE,
      };
    }

    // Shift is InstantPay eligible and have not exceeded sent home hours threshold.
    return {
      cancelShiftKey: CancelShiftKey.CANCEL_OR_SENT_HOME,
      message: NOT_EXCEEDED_SENT_HOME_HOURS_MESSAGE,
    };
  }

  // Shift has started, Facility does not offer Sent home pay.
  return {
    cancelShiftKey: CancelShiftKey.CANCEL,
    message: NO_SENT_HOME_PAY_OFFER_BY_FACILITY_MESSAGE,
  };
}

export function useGetShiftCancelFeedback(shift: Shift): CancelShiftFeedback {
  const { isSentHomeFlowV2Enabled } = useCbhFlag(CbhFeatureFlag.SENT_HOME_REQUEST_CONFIG, {
    defaultValue: FEATURE_FLAG_DEFAULT_VALUES[CbhFeatureFlag.SENT_HOME_REQUEST_CONFIG],
  });

  return getShiftCancelFeedback({
    isSentHomeFlowV2Enabled,
    shift,
  });
}
