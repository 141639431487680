import { addMinutes, parseISO } from "date-fns";

import { EtaError } from "./constants";

export interface IsValidEtaParams {
  time: Date;
  shiftStart: string;
  maxEtaBoundInMinutes: number;
}
export interface IsValidEtaResponse {
  isValid: boolean;
  error?: EtaError;
}

export function isValidEta(params: IsValidEtaParams): IsValidEtaResponse {
  const { time, shiftStart, maxEtaBoundInMinutes } = params;
  const shiftStartDate = parseISO(shiftStart);
  const maxEtaTime = addMinutes(shiftStartDate, maxEtaBoundInMinutes);

  if (time < shiftStartDate) {
    return {
      isValid: false,
      error: EtaError.BEFORE_SHIFT_START,
    };
  }

  if (time > maxEtaTime) {
    return {
      isValid: false,
      error: EtaError.AFTER_MAX_TIME,
    };
  }

  if (time < new Date()) {
    return {
      isValid: false,
      error: EtaError.BEFORE_CURRENT_TIME,
    };
  }

  return {
    isValid: true,
  };
}
