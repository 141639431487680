import { isDefined } from "@clipboard-health/util-ts";
import { parseISO } from "date-fns";
import { z } from "zod";

import { EtaError } from "./constants";
import { isValidEta } from "./isValid";

export const updateEtaFormSchema = z
  .object({
    eta: z
      .date()
      .nullable()
      .refine((value) => isDefined(value), {
        message: EtaError.NO_ETA,
      }),
    notes: z.string().optional(),
    metadata: z
      .object({
        maxEtaBoundInMinutes: z.number(),
        shiftStart: z.string(),
      })
      .deepPartial(),
  })
  .superRefine((value, context) => {
    const { eta, metadata } = value;

    if (isDefined(metadata?.maxEtaBoundInMinutes) && isDefined(metadata?.shiftStart)) {
      const { maxEtaBoundInMinutes, shiftStart } = metadata;
      const { isValid, error } = isValidEta({
        maxEtaBoundInMinutes,
        shiftStart,
        time: parseISO(String(eta?.toISOString())),
      });
      if (!isValid) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: error,
          path: ["eta"],
        });
      }

      return;
    }

    context.addIssue({
      code: z.ZodIssueCode.custom,
      message: EtaError.INTERNAL_ERROR,
      path: ["eta"],
    });
  });

export type UpdateEtaFormFields = z.infer<typeof updateEtaFormSchema>;
