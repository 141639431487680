export enum CancelShiftKey {
  CANCEL = "CANCEL",
  CANCEL_OR_SENT_HOME = "CANCEL_OR_SENT_HOME",
}

export const NO_SENT_HOME_PAY_OFFER_BY_FACILITY_MESSAGE = `This facility does not offer Sent Home Pay - proceed to clock out if you're sent home.`;

export const EXCEEDED_SENT_HOME_HOURS_MESSAGE = `The shift time has exceeded the Sent Home Pay hours. Contact support and select "Payments" if you're sent home.`;

export const NOT_EXCEEDED_SENT_HOME_HOURS_MESSAGE = `The shift has not yet passed the Sent Home Pay hours. If you're sent home, please tap "Sent Home" below or ask the facility to cancel the shift.`;
