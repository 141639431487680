import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { addMinutes } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { useController } from "react-hook-form";

import { EtaError } from "./constants";
import { type UpdateEtaFormFields } from "./schema";

interface Props {
  timezone?: string;
  maxEtaBoundInMinutes: number;
  shiftStart: Date;
}

function getEtaErrorMessage({
  error,
  maxEtaBoundInMinutes,
}: {
  error: string;
  maxEtaBoundInMinutes: number;
}): string | undefined {
  switch (error) {
    case EtaError.NO_ETA: {
      return `Please enter an ETA`;
    }

    case EtaError.BEFORE_SHIFT_START: {
      return `ETA cannot be before the shift start time`;
    }

    case EtaError.AFTER_MAX_TIME: {
      return `ETA cannot be more than ${maxEtaBoundInMinutes} minutes after the shift start time`;
    }

    case EtaError.INVALID_ETA: {
      return `Invalid ETA. Try again!`;
    }

    case EtaError.BEFORE_CURRENT_TIME: {
      return `ETA cannot be earlier than the current time`;
    }

    default: {
      return undefined;
    }
  }
}

export function EtaTimePicker(props: Props) {
  const {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
    maxEtaBoundInMinutes,
    shiftStart,
  } = props;
  const {
    field,
    fieldState: { error },
    formState: { isSubmitting },
  } = useController<UpdateEtaFormFields>({ name: "eta" });

  const localShiftStart = utcToZonedTime(shiftStart, timezone);
  const maxEtaTime = addMinutes(localShiftStart, maxEtaBoundInMinutes + 1); // maxDateTime is exclusive

  const now = utcToZonedTime(new Date(), timezone);
  const minDateTime = now > localShiftStart ? now : localShiftStart;

  const localFieldValue =
    field.value instanceof Date ? utcToZonedTime(field.value, timezone) : null;

  // Most of the time we need to open to the "hour" picker, since the ETA update
  // will usually be for the same day as the shift start. However, if the shift
  // starts close to midnight (after 11pm) we should show the "date" picker
  // first, since their ETA datetime might be for the following day. We make an
  // exception when the current date is after the shift start date (e.g. the shift
  // started at 11pm but it's now after midnight on the next day) then we should
  // default to the "hour" picker.
  const hoursUntilEndOfDay = 24 - localShiftStart.getHours();
  const isAfterShiftStartDay = now.getDate() > localShiftStart.getDate();
  const openToView = hoursUntilEndOfDay <= 1 && !isAfterShiftStartDay ? "day" : "hours";

  return (
    <Box>
      <Text variant="subtitle2" id="eta-label">
        Expected arrival time
      </Text>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          {...field}
          ampmInClock
          value={localFieldValue}
          minDateTime={minDateTime}
          maxDateTime={maxEtaTime}
          timezone={timezone}
          disabled={isSubmitting}
          openTo={openToView}
          slotProps={{
            toolbar: {
              hidden: true,
            },
            textField: {
              fullWidth: true,
              error: isDefined(error?.message),
              name: "eta",
              required: true,
              helperText: error?.message
                ? getEtaErrorMessage({ error: error.message, maxEtaBoundInMinutes })
                : undefined,
              inputProps: {
                // readOnly: true,
                "aria-labelledby": "eta-label",
              },
            },
          }}
          onChange={(newValue) => {
            const dateWithoutSeconds = newValue ? new Date(newValue.setSeconds(0, 0)) : null;
            const utcValue = dateWithoutSeconds
              ? zonedTimeToUtc(dateWithoutSeconds, timezone)
              : null;
            field.onChange(utcValue);
          }}
        />
      </LocalizationProvider>
    </Box>
  );
}
