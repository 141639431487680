import { formatTime } from "@clipboard-health/date-time";
import { Text, Title } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { IonPage } from "@ionic/react";
import { type Shift } from "@lib/interface";
import { Button, Stack } from "@mui/material";
import { AppBarHeader, BackButtonLink, PageWithHeader } from "@src/appV2/lib";
import { useHistory } from "react-router-dom";

export interface MyShiftUnavailablePageProps {
  shift: Shift | undefined;
  workerId: string | undefined;
  error: Error | undefined;
}

export function MyShiftUnavailablePage(props: MyShiftUnavailablePageProps): JSX.Element {
  const { shift, workerId, error } = props;
  const history = useHistory();

  let errorMessage = "There was an error while loading details of your shift, please try again.";
  if (!error) {
    errorMessage = "This shift is no longer available. Click below to go to my shifts page.";

    const isAssignedToSameUser = isDefined(shift?.agentId) && shift?.agentId === workerId;
    const shiftStart = shift?.start;
    const facilityName = shift?.facility?.name;
    const timeZone = shift?.facility?.tmz;

    if (
      !isAssignedToSameUser &&
      isDefined(shiftStart) &&
      isDefined(timeZone) &&
      isDefined(facilityName)
    ) {
      errorMessage = `We found someone else to work the ${formatTime(shiftStart, {
        timeZone,
      })} shift at ${facilityName}.`;
    }
  }

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title="Error"
            leftCta={<BackButtonLink alwaysUseDefaultBackTo defaultBackTo="/home/myShifts" />}
          />
        }
      >
        <Stack justifyContent="center" alignItems="center" sx={{ height: "85vh" }}>
          <Title component="h1" align="center" sx={{ marginBottom: 2 }}>
            We can&apos;t load your shift.
          </Title>
          <Text align="center" sx={{ marginBottom: 4 }}>
            {errorMessage}
          </Text>
          <Button
            variant="contained"
            onClick={() => {
              history.replace("/home/myShifts");
            }}
          >
            Go to My Shifts
          </Button>
        </Stack>
      </PageWithHeader>
    </IonPage>
  );
}
