import { TextField } from "@mui/material";
import { type ReactElement } from "react";
import { useController } from "react-hook-form";

interface Props {
  name: string;
}

export function AdditionalNotes(props: Props): ReactElement {
  const { name } = props;
  const {
    field,
    formState: { isSubmitting },
  } = useController({ name });

  return (
    <TextField
      {...field}
      multiline
      fullWidth
      label="Additional notes"
      aria-label="Additional notes"
      placeholder="Provide any additional details here for your message to the facility (optional)"
      rows={3}
      variant="outlined"
      disabled={isSubmitting}
    />
  );
}
