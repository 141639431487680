import { formatTime } from "@clipboard-health/date-time";
import { addMinutes, isValid, max } from "date-fns";

export interface GetSuggestedEtaParams {
  shiftStart: Date;
  /**
   * @default 20
   */
  defaultEtaInMinutes?: number;
}
export function getSuggestedEta(params: GetSuggestedEtaParams): Date {
  const { shiftStart, defaultEtaInMinutes = 20 } = params;
  const baseTime = max([shiftStart, new Date()]);
  return addMinutes(baseTime, defaultEtaInMinutes);
}

export interface EtaMessageParams {
  shiftStart: Date;
  eta: Date;
  timeZone: string;
}
export function getEtaMessage(params: EtaMessageParams): string {
  const { eta, shiftStart, timeZone } = params;

  return `Hi I'm running late for my ${formatTime(shiftStart, {
    timeZone,
  })} shift. I will arrive at approximately ${
    isValid(eta) ? formatTime(eta, { timeZone }) : "..."
  }.`;
}
